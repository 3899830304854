import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditPostButton from "../EditPostButton";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { Link } from "react-router-dom";
import { postsRef } from "../firebase";
export default function Post(props: any) {
  const { post, showContent } = props;
  if (post) {
    return (
      <Card className="post">
        <CardHeader
          title={<Link to={"/post/" + post.id}>{post.title}</Link>}
          subheader={
            new Date(post.published).toLocaleDateString() +
            " " +
            new Date(post.published).toLocaleTimeString()
          }
        ></CardHeader>
        {showContent && (
          <CardContent>
            <p dangerouslySetInnerHTML={{ __html: post.content }}></p>
          </CardContent>
        )}
        <EditPostButton />
        <IconButton
          aria-label="delete"
          onClick={(e) => postsRef.doc(post.id).delete()}
        >
          <DeleteIcon fontSize="large" />
        </IconButton>
      </Card>
    );
  } else {
    return <div></div>;
  }
}
