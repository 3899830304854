import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Header from "./components/Header";
import Main from "./views/Main";
import Footer from "./components/Footer";
import ShowPost from "./views/ShowPost";
import registerServiceWorker from "./serviceWorker";
function App() {
  registerServiceWorker();
  return (
    <React.Fragment>
      <Container>
        <Header title="Blog" />
        <Router>
          <Link to="/">Home</Link>
          <Route path="/" exact component={Main} />
          <Route path="/post/:postId" component={ShowPost} />
        </Router>
        <Footer />
      </Container>
    </React.Fragment>
  );
}

export default App;
