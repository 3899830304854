import React from "react";

export default function Main() {
  const config = process.env.REACT_APP_ENVIRONMENT;
  const thisYear = new Date().getFullYear();
  return (
    <footer>
      <div className="footer">
        &copy; Copyright {thisYear} - {config}
      </div>
    </footer>
  );
}
