import React from "react";
import Post from "./Post";
import Divider from "@material-ui/core/Divider";
interface PostState {
  id: string;
  title: string;
  content: string;
  published: string;
}
interface PostListParams {
  posts: PostState[];
  count: number;
}

const PostList: React.FC<PostListParams> = ({
  posts,
  count,
}: PostListParams): JSX.Element => {
  const postsy = posts;
  const county = count;
  let showIndex = county + 1;
  return (
    <>
      {postsy.slice(1, showIndex).map((post: PostState, i: number) => (
        <React.Fragment key={post.id}>
          <Post post={post} />
          {i < postsy.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </>
  );
};
export default PostList;
