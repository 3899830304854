import React, { useState, useEffect } from "react";
import { postsRef, auth } from "../firebase";
import Post from "../components/Post";
import { RouteComponentProps } from "react-router-dom";

interface MatchParams {
  postId: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

const ShowPost: React.FC<Props> = ({ match }: Props): JSX.Element => {
  const postId = match.params.postId;

  const [post, displayPost] = useState<any>();
  useEffect(() => {
    if (!post) {
      auth.then(() => {
        postsRef
          .doc(postId)
          .get()
          .then((snapshot) => {
            displayPost({
              id: snapshot.id,
              title: snapshot.data()!.Title,
              content: snapshot.data()!.Content,
              published: snapshot.data()!.Published.toDate().toString(),
            });
          });
      });
    }
    return () => {
      //   console.log("showpost", postId);
    };
  }, [post, postId]);

  return <Post post={post} showContent={true}></Post>;
};

export default ShowPost;
// export default function ShowPost(props: any) {
//   //const { post } = props;
//   //   const postId = useParams<string | undefined>();
//   //   const [post, displayPost] = useState<any>();
//   //   useEffect(() => {
//   //     if (!post) {
//   //       auth.then(() => {
//   //         // postsRef
//   //         //   .doc(postId)
//   //         //   .get()
//   //         //   .then((snapshot) => {
//   //         //     // displayPost({
//   //         //     //   id: postId,
//   //         //     //   title: snapshot.data()!.Title,
//   //         //     //   content: snapshot.data()!.Content,
//   //         //     //   published: snapshot.data()!.Published.toDate().toString(),
//   //         //     // });
//   //         //   });
//   //       });
//   //     }
//   //     return () => {
//   //       //   console.log("showpost", postId);
//   //     };
//   //   }, [post]);
//   return;
// }
