import { useState, useEffect } from "react";
import Post from "../components/Post";
import PostList from "../components/PostList";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { postsRef, auth } from "../firebase";

interface PostState {
  id: string;
  title: string;
  content: string;
  published: string;
}
const Main: React.FC = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [posts, displayPosts] = useState<PostState[]>([]);
  useEffect(() => {
    if ((!posts || posts.length === 0) && loading === false) {
      setLoading(true);
      auth.then(() => {
        postsRef
          .orderBy("Published", "desc")
          .limit(6)
          .get()
          .then((snapshot) => {
            let items = snapshot.docs;
            let newState = [];
            for (let item in items) {
              newState.push({
                id: items[item].id,
                title: items[item].data().Title,
                content: items[item].data().Content,
                published: items[item].data().Published.toDate().toString(),
              });
            }
            displayPosts(newState);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
    return;
  }, [posts, loading]);
  if (posts) {
    let firstPost = posts[0];
    return (
      <main>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper>
              <Post post={firstPost} showContent={true}></Post>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper>
              <PostList posts={posts} count={2}></PostList>
            </Paper>
          </Grid>
        </Grid>
      </main>
    );
  } else {
    return <main></main>;
  }
};
export default Main;
