import firebase from 'firebase/app'
import "firebase/auth"
import "firebase/firestore"
const config = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DB,
    projectId: process.env.REACT_APP_PID,
    storageBucket: process.env.REACT_APP_SB,
    messagingSenderId: process.env.REACT_APP_SID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MID,
    fsUsr: process.env.REACT_APP_FSUSR,
    fsPwd: process.env.REACT_APP_FSPWD,
};
firebase.initializeApp(config);
export const auth = firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {
        firebase.auth().signInWithEmailAndPassword(config.fsUsr!, config.fsPwd!)
            .then((result) => {
                return result;
            }).catch((error) => {
                console.log(error);
            });
    });
        
const firestoreRef = firebase.firestore();

export const postsRef = firestoreRef.collection("posts");
export default firebase;